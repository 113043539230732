@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
body {
  font-family: "Poppins", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
body {
  font-family: "Lato", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
body {
  font-family: "Open Sans", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
body {
  font-family: "Roboto", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700; /* Bold as you prefer */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 2rem;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  background-color: #e7e7e8;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

h2 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 35px;
  line-height: 3rem;
}

h3 {
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 1px;
}
h4 {
  font-weight: 300;
  letter-spacing: 4px;
}
h5 {
  font-weight: 300;
  font-size: 17px;
}
p {
  font-weight: 100;
  font-size: 15px;
  line-height: 2rem;
}
a {
  color: inherit;
  text-decoration: none;
}
input.subscription-name:focus,
input.subscription-name:not(:placeholder-shown) {
  background: none; /* or you can use 'transparent' */
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: rgb(205, 205, 205) !important;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  /* This will override the background color and text color transition of autofill */
}

button {
  cursor: pointer;
}

.error-messages {
  color: rgb(241, 94, 94);
  margin-bottom: 10px;
}

.home__hero {
  background-image: url("https://res.cloudinary.com/dh8p6vqik/image/upload/v1699393049/HALE_SAMUEL_4-5_05_mndv9y.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.about__hero {
  background-image: url("https://res.cloudinary.com/dh8p6vqik/image/upload/v1699392952/section__1__img_xniyst.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.about__hero h1 {
  color: white;
}

.hero__flow {
  height: 280px;
}

.section__1__about {
  margin: 10%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@keyframes breathe-animation {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.25; /* Lower opacity at the start and end to create a fade effect */
  }
  50% {
    transform: scale(1.2);
    opacity: 1; /* Full opacity when scaled up */
  }
  25% {
    transform: scale(1.1);
    opacity: 0.75;
  }
}

@keyframes breathing {
  0%,
  24% {
    content: "andas.";
  }
  25%,
  49% {
    content: "in.";
  }
  50%,
  74% {
    content: "andas.";
  }
  75%,
  100% {
    content: "ut.";
  }
}

.breathing-text {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 30px;
  animation: breathe-animation 8s infinite ease-in-out;
  color: #747474;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 15px;
}
.breathing-text::before {
  content: "breathe.";
  animation: breathing 12s infinite;
}

.cta {
  background-color: #fcdf2e;
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  transition: 1s ease;
  margin-top: 30px;
  cursor: pointer;
}

.cta h4 {
  margin-left: -30px;
  width: 100%;
  transition: 1s ease;
}

.cta:hover h4 {
  margin-left: 0px;
}
.cta:hover {
  transform: translateX(-10%);
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* Initial state: fully transparent */
  }
  100% {
    opacity: 1; /* Final state: fully opaque */
  }
}
@keyframes rollOut {
  0% {
    width: 0;
    opacity: 0; /* Start completely transparent */
  }
  100% {
    width: 100%;
    opacity: 1; /* Fully visible */
  }
}

.policy-container {
  padding: 8%;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.policy-container h4 {
  margin-top: 20px;
  font-weight: 700;
}
.hero__description {
  position: relative;
  overflow: hidden; /* Ensures that content doesn't spill outside the container */
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  animation: rollOut 1s forwards; /* Uses our animation, lasts 2 seconds, and maintains the state at the last keyframe */
  opacity: 0; /* Initially hidden until animation starts */
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

.hero__description h5 {
  position: relative;
  opacity: 0; /* Make the text initially invisible */
  animation: 1s ease-out 1s 1 normal forwards running fadeIn; /* Handles the fading in */
  color: #a4a4a4;
}

.home__credentials {
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #657268;
  color: white;
  text-align: center;
  gap: 3rem;
  display: flex; /* Aligns children (credentials) in a row */
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents the content from wrapping to the next line */
  scrollbar-width: thin; /* Optional: for a thinner scrollbar */
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent; /* Optional: custom scrollbar styling */
  -webkit-overflow-scrolling: touch; /* Optional: for momentum-based scrolling on touch-enabled devices */
  /* Additional styling like padding, margin, etc., if necessary */
}

.credential__1,
.credential__2,
.credential__3 {
  flex-shrink: 0; /* Prevents the items from shrinking in the flex container */
  margin-right: 20px; /* Adds some space between the items */
  width: 60%; /* Or any specific width you prefer for the items */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;
}

.credential__1 h3,
.credential__2 h3,
.credential__3 h3 {
  margin-bottom: 10px;
}

/* Optional: Custom scrollbar styles for WebKit browsers */
.home__credentials::-webkit-scrollbar {
  height: 6px; /* Custom height */
}

.home__credentials::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7); /* Custom color */
  border-radius: 20px; /* Rounded corners */
  border: 1px solid transparent; /* Makes the thumb a bit smaller than the track */
  background-clip: padding-box; /* Clips the background color to the padding box, over the border */
}

.section__1 {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  text-align: left;
  padding: 12% 10% 10% 10%;
}
.section__1 p {
  font-size: 20px;
  font-weight: 300;
  z-index: 100;
}
.section__1 img {
  width: 100%;
}
.section__1--quote {
  margin-top: 15px;
  position: relative;
  z-index: 1;
}
.section__1--quote::before,
.section__1--quote::after {
  content: "“";
  position: absolute;
  z-index: -1;
  font-size: 8rem;
  height: 40px;
  color: #fce02ec2;
}

.section__1--quote::before {
  top: -3.5rem;
  left: -1rem;
}
.section__1--quote::after {
  content: "“";
  bottom: 2rem;
}

.section__2 {
  padding: 10% 10px 10% 10px;
  background-color: #657268;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: center;
}

.section__2 img {
  width: 20%;
}
.section__2--desc {
  font-size: 15px;
  text-align: center;
  width: 70%;
}

.center__city {
  font-size: 10px;
  text-align: center;
  width: 70%;
}

.line {
  height: 10px;
  width: 80%;
  margin-bottom: 10px;
  border-radius: 10px;
  background: #fcdf2e;
}

.section__2--centers {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 8rem;
}

.center__1,
.center__2,
.center__3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 1s ease-in-out;
  cursor: pointer;
}

.center__1:hover,
.center__2:hover,
.center__3:hover {
  transform: scale(1.05);
}
.retreat__page__container {
  padding-top: 100px;
}

.retreats__grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2.5rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 4%;
}
.retreats__container {
  padding: 4% 10px 10% 10px;
}

.retreats__header {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  padding: 6% 6% 0% 6%;
  gap: 2rem;
}

.retreats__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.retreats__card {
  width: 380px;
  background: #657268;
  padding-bottom: 10px;
  border-radius: 2%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: 1s ease;
  border: 6px solid #686f69;
}

.availability {
  font-style: italic;
}
.retreats__card:hover {
  transform: translateY(-5px);
}

.first__content {
  padding: 4%;
  display: flex;
  flex-direction: column;
  color: white;
}
.second__content {
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.second__content p {
  color: white;
  letter-spacing: 1.5px;
}

.retreats__card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2%;
}

.read__more {
  border: none;
  color: white;
  background: none;
  padding-left: 10px;
  cursor: pointer;
}

.description {
  line-height: 1.8;
}

.card__line {
  margin-top: 15px;
  margin-bottom: 10px;
  width: 25%;
  height: 4px;
  background-color: #fcdf2e;
}

.search__div {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.retreats__hero {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.search__input {
  position: relative;
  z-index: 10;
  width: 80%;
}
.input-wrapper {
  width: 100%;
  height: 60px;
}

.search__input p {
  text-align: left;
  background: #fcdf2e;
  width: fit-content;
  padding: 1% 2% 0% 2%;
  font-weight: 700;
  color: grey;
}

.search__input h2 {
  text-align: center;
  /* background: #fcdf2e; */
  font-weight: 700;
  margin: 0;
  color: rgb(162, 162, 162);
}

.search__input input:focus + .search-icon {
  display: none;
  transition: 1s ease-in-out;
}

.search__div input {
  width: 100%;
  height: 60px;
  padding: 2% 50px 2% 2%;
  border: 4px #fcdf2e solid;
  background-color: rgba(255, 255, 255, 0.173);
  font-size: 17px;
  font-weight: 300;
  color: grey;
}

.search-icon {
  position: absolute;
  right: 10px; /* Adjust as needed */

  height: 50px;
  width: 50px;
  z-index: 10;
  pointer-events: none; /* So you can click 'through' the icon */
  transition: 1s ease-in-out;
}

.retreats__grid__page {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
}

.retreats__container__page {
  padding-top: 6%;
  padding-bottom: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.retreats__card__page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70%;
  background: #657268;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: 1s ease;
  border: 6px solid #686f69;
}

.retreats__card__page img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2%;
}
.img__frame__page {
  width: 100%;
}

.second__content__page {
  color: white;
  letter-spacing: 1.5px;
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: left;
  grid-column: span 2;
}
.checkbox__container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #c6c6c6;
}

.checkbox__container input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.booking__div {
  padding-left: 6%;
  padding-right: 6%;
}
.friend__success {
  background: #444d45f2;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  padding: 4%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.friend__cta {
  background-color: #fcdf2e;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  transition: 1s ease;
  margin-top: 30px;
  cursor: pointer;
}

.cart {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.friend__cta h4 {
  margin-left: -10px;
  width: 100%;
  transition: 1s ease;
}

.friend__cta:hover h4 {
  margin-left: 10px;
}
.friend__cta:hover {
  transform: translateX(-10%);
}

.stripe__form {
  width: 70%;
  padding: 5%;
  background-color: #657268;
}
.stripe__form h2 {
  color: white;
  margin-bottom: 20px;
}
.stripe__form button {
  margin-top: 5%;
  width: 100%;
  border-radius: 2px;
  border-color: #b2c0b6;
}

.StripeElement {
  background: #fefffebf;
  padding: 2%;
  border-radius: 10px;
}

.choose__payment__method {
  margin-top: 10%;
}

.payment__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0% 10% 10% 10%;
  padding: 5%;
  background-color: #657268;
  color: white;
  border-radius: 10px;
}
.payment__details h4 {
  margin-bottom: 2.5%;
  margin-top: 2.5%;
}

.waiting__popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.waiting__popup__container {
  background-color: #657268;
  padding: 30px 50px 30px 50px;
  color: white;
  border-radius: 5px;
  max-width: 50%;
  max-height: 80%;
  overflow-y: auto;
}

@media screen and (max-width: 550px) {
  .waiting__popup__container {
    max-width: 90%;
    padding: 20px;
  }

  .breathing-text {
    display: none;
  }
  .section__2--centers {
    grid-template-columns: 1fr;
    gap: 4rem;
  }

  .retreats__header {
    padding: 6% 2% 0% 2%;
  }

  .section__2 img {
    width: 60%;
  }

  .section__1__about {
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section__2--desc {
    width: 100%;
  }
  .hero__description h5 {
    font-size: 12px;
  }
  .home__credentials h3 {
    line-height: 1.5;
  }
  .section__2 {
    padding: 10% 20px 20% 20px;
  }
  .section__1 {
    padding-top: 10%;
    grid-template-columns: 1fr;
    padding-left: 4%;
    padding-right: 4%;
  }
  .section__1--grid__1 {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section__1 img {
    display: none;
  }
  .section__2 {
    padding-left: 2%;
    padding-right: 2%;
  }
  .section__2--centers p {
    line-height: 1.5;
  }

  .retreats__container {
    padding: 10% 20px 10% 10px;
  }

  .second__content_page {
    padding: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .second__content_page p {
    color: white;
    letter-spacing: 1.5px;
  }

  .retreats__card__page {
    display: flex;
    flex-direction: column;
    width: 99%;
    background: #657268;
    padding-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    transition: 1s ease;
    border: 6px solid #686f69;
  }
  .stripe__form {
    width: 98%;
  }
}
