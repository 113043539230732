.hidden {
  display: none !important;
}
.navbar__container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ecececc4;
  padding: 2% 4% 1% 4%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
.navbar__container a {
  color: grey;
  text-decoration: none;
  font-weight: 300;
}

.samuel {
  font-size: 22px;
}

.navbar__logo {
  max-width: 100px;
  max-height: 50px;
  border-radius: 50px;
}

.navbar__menu {
  display: flex;
  gap: 75px;
  cursor: pointer;
}
.navbar__menu a {
  text-decoration: none;
  color: grey;
  border-bottom: 3px solid transparent;
  transition: 0.3s ease-in-out;
  /* transition: border-bottom-color 1s ease;  */
}
.navbar__menu a:hover {
  color: #444242;
  transform: translateY(-2px);
  border-bottom: 4px solid #e7d454;
}
.navbar__menu img {
  height: 30px;
  transition: 0.3s ease-in-out;
}
.navbar__menu img:hover {
  transform: translateY(-2px);
}
.page__content {
  padding-left: 2%;
  padding-right: 2%;
}

@media (max-width: 560px) {
  .navbar__menu {
    position: fixed;
    right: -100%;
    top: 0;
    flex-direction: column;
    background-color: #ecececd8;
    width: 100%;
    height: 100%;
    align-items: center;
    transition: right 1s ease-in-out;
    position: fixed;
    display: flex;
    justify-content: center;
  }
  .navbar__menu a {
    font-size: 30px;
    font-weight: 400;
    color: rgb(101, 101, 101);
  }

  .gratis__samtal {
    background-color: transparent !important;
    border-radius: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  input.subscription-name:focus,
  input.subscription-name:not(:placeholder-shown) {
    background: none; /* or you can use 'transparent' */
  }

  .navbar__menu a:hover {
    color: rgb(41, 41, 41);
  }

  .navbar__menu.active {
    right: 0;
  }

  .hamburger-menu {
    position: relative;
    display: block;
    cursor: pointer;
    z-index: 1002;
  }
  .bar-rotate-45deg {
    transform: rotate(200deg);
  }

  .bar-rotate--45deg {
    transform: rotate(-200deg);
  }

  .hamburger__bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: rgb(136 136 136);
    transition: all 0.3s ease-in-out;
  }

  .hidden {
    display: none !important;
  }
}

/*-------------FOOTER------*/

.container {
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 10px;
}

.container li {
  border: 1px solid #fcdf2e;
  border-radius: 40px;
  padding: 2px 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.container ul {
  list-style-type: none;
  padding: 0;
}

.the-arrow {
  width: 64px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.text {
  color: #fcdf2e;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #fcdf2e;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #fcdf2e;
}
.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 64px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  transform: rotate(40deg);
}
.the-arrow.-right > .shaft:after {
  transform: rotate(-40deg);
}
.the-arrow > .shaft {
  background-color: #fcdf2e;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #fcdf2e;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  transform-origin: bottom right;
}
.animated-arrow {
  display: inline-block;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 1s;
  padding: 6px;
}
.animated-arrow:hover {
  color: #fcdf2e;
}
.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #fcdf2e;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before,
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 8px;
  transition-delay: 0.1s;
  background-color: #fcdf2e;
}
.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}
.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}
.animated-arrow:hover > .main {
  transform: translateX(80px);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before,
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.2s;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main {
  display: flex;
  align-items: center;
  transition: all 0.5s;
}
.animated-arrow > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main > .the-arrow {
  position: relative;
}

.footer__div {
  background: #667268;
  padding-top: 40px;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 40px;
  color: white;
}

.footer__content h3 {
  font-weight: 700;
  font-size: 15px;
}
.rights {
  text-align: center;
  margin-top: 100px;
}

.footer__div a {
  color: white;
  text-decoration: none;
}

.footer__contact {
  display: flex;
  flex-direction: column;
}

.footer__form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.footer__input {
  grid-gap: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

.group {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.group input,
.group textarea {
  background: none;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 50%, 0.396);
  border-radius: 0;
  color: #c6c6c6;
  display: block;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  width: 100%;
  resize: none;
}

.group input:focus,
.group textarea:focus {
  outline: none;
}
.group input:focus ~ label,
.group textarea:focus ~ label,
.group input:valid ~ label,
.group textarea:valid ~ label {
  top: -14px;
  font-size: 12px;
  color: #fcdf2e;
}

.group input:focus ~ .bar:before,
.group textarea:focus ~ .bar:before {
  width: 100%;
}

.group label {
  color: #c6c6c6;
  font-size: 16px;
  font-weight: 400;
  left: 5px;
  pointer-events: none;
  position: absolute;
  top: 10px;
  transition: all 0.3s ease;
}

.subscription--name {
  background: none;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 50%, 0.396);
  border-radius: 0;
  color: #c6c6c6;
  display: block;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  width: 100%;
}

.footer__content {
  margin-top: 4rem;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.gratis__samtal {
  background-color: #e7d454;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subscription__input--interest {
  grid-column: span 2;
}
@media screen and (max-width: 500px) {
  .footer__content {
    gap: 2rem !important;
    display: flex;
    flex-direction: column !important;
  }
}

@media screen and (min-width: 500px) {
  .food__div {
    height: 100% !important;
  }
}

.footer__div a,
.div--btn a {
  font-weight: 400;
}

.footer__social__icon {
  height: 20px;
  padding-right: 10px;
  cursor: pointer;
}

.bar {
  display: block;
  position: relative;
  width: 100%;
}

.bar:before {
  background: #fcdf2e;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.3s ease;
  width: 0;
}
